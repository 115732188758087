.recommendList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &::after {
    content: "";
    width: calc(370 / 1200 * 100%);
  }
}

.recommendListItem {
  width: calc(370 / 1200 * 100%);
  margin-bottom: 3rem;

  background-color: white;
  border: 1px solid var(--grey8);
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
}

.recommendListLink {
  width: 100%;
  padding: 5rem 0.8rem 5rem;
  flex-grow: 1;
}

.recommendTop {
  margin-top: 1.6rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.documentListLink {
  width: 100%;
  padding: 4.2rem 1.6rem 2.4rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.recommendCategoryList {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}

.sampleCategory {
  width: fit-content;
  margin-bottom: 1.2rem;
  padding: 0.1rem 0.3rem;
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--blue5);
  background-color: var(--blue6);
  border-radius: 0.4rem;
  &:not(:last-of-type) {
    margin-right: 1.6rem;
  }
}

.sampleTitle {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-weight: bold;
}

.sampleText {
  line-height: 2.8rem;
}
.documentText {
  margin-top: 2.9rem;
  margin-bottom: 4rem;
  line-height: 2.8rem;
}

.recommendLogo {
  display: block;
  width: fit-content;
  margin-left: auto;
  width: 30%;
  margin-left: auto;
}

.tagList {
  display: flex;
  flex-wrap: wrap;
}
.tagItem {
  padding: 0 2rem;
  border-radius: 1rem;
  background-color: #f0f7fc;
  font-size: 1.2rem;
  // font-weight: 300;
  color: var(--black5);
  margin-bottom: 1.5rem;
  &:not(:last-of-type) {
    margin-right: 1.5rem;
  }
}

@media screen and (max-width: 800px) {
  .recommendListLink {
    padding: 4rem 0.8rem 6rem;
  }
}

@media screen and (max-width: 600px) {
  .recommendList {
    background-color: white;
    padding: 0 2rem;
  }
  .recommendListItem {
    width: 100%;
    border: none;
    &:not(:last-of-type) {
      margin-bottom: 1.6rem;
    }
  }
  .recommendListLink,
  .documentListLink {
    padding: 3rem 1.6rem 1rem;
  }
  .smapleMoreLink {
    margin-top: 3.2rem;
  }
}
