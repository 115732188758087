.pagination {
  margin-top: 2.9rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  li {
    width: 4.1rem;
    height: 4.6rem;
    margin: 0 0.7rem 0.7rem;
    text-align: center;
    line-height: 4.6rem;
    border: 1px solid #000;
    color: var(--prussian-blue);
    background-color: white;
    &.isActive {
      background-color: #000;
      color: white;
    }
    &.isSplit {
      width: 2.4rem;
      border: none;
      margin: 0 0.3rem;
    }
    &.isText {
      a,
      button {
        font-size: 1rem;
      }
    }
    a,
    button {
      display: block;
      width: 100%;
      line-height: 4.6rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .pagination {
    li {
      width: 3.5rem;
      height: 4rem;
      line-height: 4rem;
      margin: 0 0.5rem 0.7rem;
      a,
      button {
        line-height: 4rem;
      }
    }
  }
}
