.card {
    display: flex;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    max-height: 108px;
}

.cardImage {
    object-fit: cover;
    margin-right: 1rem;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.cardContent {
    flex-grow: 1;
    position: relative;
    padding-right: 4rem;
}

.cardTitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 17.6px;
    margin: 1rem;
    line-height: 1.2;
    max-height: 1.2em;
}

.cardDescription {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #666;
    margin-left: 1rem;
    max-height: 2.8em;
    line-height: 1.4;
}

.cardArrow {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: #1b2228;
}

@media screen and (max-width: 600px) {
    .cardContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .cardDescription {
        display: none;
    }
    .cardTitle {
        font-size: 18px;
        font-weight: bold;
    }
}
