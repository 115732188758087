.contact {
  padding: 4.6rem 1.5rem;
  position: relative;
  color: white;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--mask-black);
    z-index: 2;
  }
}
.bgImage {
  object-fit: cover;
}
.contactInner {
  position: relative;
  z-index: 3;
}

.contactText1 {
  margin-bottom: 0.8rem;
  font-size: 4.8rem;
  font-weight: bold;
  letter-spacing: 0.004em;
  text-align: center;
}

.contactText2 {
  margin-bottom: 0.8rem;
  font-size: 2.4rem;
  line-height: 4.2rem;
  letter-spacing: 0.05em;
  text-align: center;
  font-weight: 300;
}

.contactTel {
  width: fit-content;
  margin: 0 auto;
  color: white;
  font-size: 3.6rem;
  letter-spacing: 0.1em;
  // font-weight: bold;
}

.contactText3 {
  text-align: center;
  font-weight: 300;
}

.contactLinks {
  margin-top: 3.6rem;
  display: flex;
  justify-content: center;
  a {
    width: 24rem;
    line-height: 6.4rem;
    border-radius: 0.4rem;
    text-align: center;
    letter-spacing: 0.08rem;
  }
  a:nth-of-type(1) {
    margin-right: 2rem;
  }
}

.contactDownloadLink {
  color: white;
  border: 2px solid;
  background-color: var(--blue4);
}

.contactLink {
  color: var(--blue4);
  border: 2px solid;
  background-color: white;
}

@media screen and (max-width: 800px) {
  .contactText1 {
    font-size: 6vw;
  }
  .contactText2 {
    font-size: 3vw;
    line-height: 1.75;
  }
  .contactTel {
    font-size: 5vw;
  }
}

@media screen and (max-width: 600px) {
  .contactLinks {
    margin-top: 2.4rem;

    a {
      width: 16rem;
      font-size: 1.4rem;
      line-height: 4rem;
    }
    a:nth-of-type(1) {
      margin-right: 1rem;
    }
  }
}
