.fadeLogoSection {
  padding-top: 8rem;
  padding-bottom: 8rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

@keyframes text {
  0% {
    background-size: 500% 500%;
    background-position: 0;
  }
  50% {
    background-size: 500% 500%;
    background-position: 100%;
  }
  100% {
    background-size: 500% 500%;
    background-position: 0;
  }
}

.fadeLogoTitle {
  text-align: center;
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 6rem;

  span {
    background-clip: text !important;
    // background-color: red;
    color: transparent;
    background-image: linear-gradient(
      to right,
      #1c64f2,
      #3f83f8 30%,
      #000
    ) !important;
    animation: text 5s ease infinite;
  }
}
@keyframes side-in {
  0% {
    transform: translateX(-100%);
  }
  45% {
    transform: translateX(12%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadelogoListWrap {
  overflow: hidden;
  width: 70%;
  margin: 0 auto;
  &.isActive {
    .fadelogoList {
      animation: side-in 1s forwards ease-out;
      &:last-of-type {
        animation-delay: 0.5s;
      }
    }
  }
  &.isActive2 {
    .fadelogoList2 {
      li {
        opacity: 0;
        animation: fade-in 0.7s forwards ease-out;
        &:nth-of-type(2) {
          animation-delay: 0.4s;
        }
        &:nth-of-type(3) {
          animation-delay: 0.8s;
        }
      }
    }
  }
}
.fadelogoList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  transform: translateX(-100%);
  li {
    width: 22%;
    margin-bottom: 4rem;
    position: relative;
    text-align: center;
  }
  img {
    filter: grayscale(100%);
    opacity: 0.5;
    &:hover {
      opacity: 1;
      filter: none;
    }
    @media screen and (min-width: 1000px) {
      max-width: 12rem;
    }
  }
}
.fadelogoList2 {
  max-width: 60rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  li {
    width: 30%;

    position: relative;
  }
  img {
    filter: grayscale(100%);
    opacity: 0.5;
    &:hover {
      opacity: 1;
      filter: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .fadeLogoSection {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .fadeLogoTitle {
    font-size: 2.4rem;
  }
  .fadelogoList {
    align-items: center;
    li {
      width: 44%;
      padding-top: 15%;
      margin-bottom: 3rem;
      &:nth-of-type(1) {
        padding-top: 13%;
      }
      &:nth-of-type(2) {
        padding-top: 16%;
      }
      &:nth-of-type(3) {
        padding-top: 10%;
      }
      &:nth-of-type(4) {
        padding-top: 27%;
      }
    }
    &:nth-of-type(2) {
      li:nth-of-type(1) {
        padding-top: 11%;
      }
      li:nth-of-type(2) {
        padding-top: 14%;
      }
      li:nth-of-type(3) {
        padding-top: 8%;
      }
      li:nth-of-type(4) {
        padding-top: 5%;
      }
    }
  }
  .fadelogoList2 {
    justify-content: center;
    li {
      width: 60%;
      padding-top: 40%;
    }
  }
}
