.main {
  flex-grow: 1;
}

.title {
  margin-bottom: 0.8rem;
  font-size: 3.6rem;
  font-weight: bold;
}
.subCategoryList {
  margin-bottom: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.subCategoryItem {
  width: 24%;
  margin-bottom: 1rem;
  background-color: var(--white2);
  border-radius: 1rem;
  &.noMargin {
    margin-bottom: 0;
  }
}
.subCategoryButton {
  width: 100%;
  padding: 0.8rem;
  display: flex;
  align-items: center;
}

.subCategoryImage {
  width: 5rem;
  height: 5rem;
  margin-right: 1.5rem;
  position: relative;
  flex-shrink: 0;
  img {
    object-fit: cover;
  }
}

.subCategoryText {
  color: var(--grey5);
  font-size: 1.6rem;
  font-weight: bold;
}

@media screen and (max-width: 1300px) {
  .subCategoryItem {
    width: 49%;
  }
}
@media screen and (max-width: 1000px) {
  .title {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 600px) {
  .title {
    font-size: 2.4rem;
  }
  .subCategoryImage {
    width: 3.6rem;
    height: 3.6rem;
  }
  .subCategoryText {
    font-size: 1.4rem;
  }
}
