@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.bottomBanner {
  position: fixed;
  bottom: 4rem;
  left: 2rem;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  animation: fade-in 0.3s 1s forwards;

  // a {
  //   &:hover {
  //     opacity: 1;
  //   }
  // }
  button {
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 0rem;
    top: -2.4rem;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    & > span {
      width: 50%;
      height: 0.1rem;
      position: relative;
      & > span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--black6);
        &:nth-of-type(1) {
          transform: rotate(-45deg);
        }
        &:nth-of-type(2) {
          transform: rotate(45deg);
        }
      }
    }
  }
}

.texts {
  background-color: white;
  padding-top: 0.8rem;
  padding-bottom: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: var(--shadow3);
  p {
    margin-bottom: 2.1rem;
    font-weight: bold;
    text-align: center;
    color: var(--blue12);
  }
  a {
    width: 25rem;
    margin: 0 auto;
    line-height: 4rem;
    border-radius: 2rem;
    color: white;
    font-weight: bold;
    text-align: center;
    background-color: var(--orange);
  }
}
.image {
  width: 30rem;
}

@media screen and (max-width: 600px) {
  .bottomBanner {
    left: 1.5rem;
    bottom: 2rem;
    display: none;
  }
  .image {
    width: 18rem;
  }
}
