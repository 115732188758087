.postList {
  max-width: 81rem;
}

.postListItem {
  width: 100%;
  display: flex;
  &:not(:last-of-type) {
    margin-bottom: 3rem;
  }
}

.postLeft {
  width: 41.5%;
  // width: 33.6rem;
  flex-shrink: 0;
}
.postImageWrap {
  padding-top: calc(9 / 16 * 100%);
  position: relative;
  img {
    object-fit: cover;
  }
}

.postCard {
  flex-grow: 1;
  margin-left: 2rem;
  padding: 1.8rem 3rem 1rem 3rem;
  border: 1px solid var(--color-lightest);
  border-radius: 1rem;
}

.postCardCateories {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.6rem;
}

.postCardTop {
  margin-bottom: 0.2rem;
  font-size: 1.2rem;
  margin-right: 2rem;
  color: var(--blue);
}

.postCardTitle {
  margin-bottom: 2rem;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.25;
}

.postCardBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.postCardDate {
  font-size: 1.2rem;
  color: var(--color-light);
}

.postCardLinks {
  display: flex;
  align-items: center;
  a:not(:last-of-type) {
    margin-right: 1.4rem;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 1260px) {
  .postCard {
    padding: 1.4rem 1.6rem;
  }
  .postCardTitle {
    font-size: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .postListItem {
    flex-direction: column;
  }

  .postLeft {
    width: 100%;
  }
  .postCard {
    margin-left: 0;
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}
