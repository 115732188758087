.inner {
  display: flex;
  max-width: 106rem;
  margin: 0 auto;
  padding: 5rem 2rem;
}
.contents {
  width: 51%;
  margin-right: 2.8rem;
  padding: 0 2.4rem;
  flex-shrink: 0;
}

.formWrap {
  flex-grow: 1;
}

.enHeading {
  margin-top: 3rem;
  margin-bottom: 1.8rem;

  font-weight: 600;
  font-size: 14px;
  position: relative;
  display: inline-block;
  padding: 12px 28px;
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
  word-wrap: break-word;
  word-break: keep-all;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50px;
    border: 3px solid transparent;
    background-image: linear-gradient(
      135deg,
      #be72ff 0%,
      #5f8aff 25%,
      #00a3ff 50%,
      #1cbad0 75%,
      #37d1a1 100%
    );
    background-origin: border-box;
    background-clip: border-box;
    -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
    -webkit-mask-clip: padding-box, border-box;
    -webkit-mask-composite: destination-out;
    mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
    mask-clip: padding-box, border-box;
    mask-composite: exclude;
  }
}

.heading {
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 32px;
}

.text {
  line-height: 2;
}
@media screen and (max-width: 1000px) {
  .inner {
    flex-direction: column;
    max-width: 70rem;
    margin: 0 auto;
  }
  .contents {
    width: 100%;
  }

  .formWrap {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 600px) {
  .inner {
    padding: 2rem 1.5rem 5rem;
  }
  .contents {
    padding: 0;
    margin-right: 0;
  }
}
