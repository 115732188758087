.container {
  padding: 4rem 2rem;
}

.sectionInner {
  max-width: 150rem;
  width: 100%;
  padding: 0 4rem;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

@keyframes loop {
  0% {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes loop2 {
  0% {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.firstView {
  position: relative;
  padding: 2rem 0 4rem;
}
.fvImage {
  object-fit: cover;
}

.fvInner {
  max-width: 136rem;
  width: 100%;
  padding: 0 4rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.fvTexts {
  width: 38%;
}
.fvMessage {
  margin-bottom: 1.2em;
  font-size: 2.4rem;
  font-weight: bold;
}

.fvHeading {
  font-size: 4rem;
  margin-bottom: 0.7em;
  color: var(--blue2);
  line-height: 1.45;
}

.fvHeadingItem1 {
  font-weight: bold;
}
.accent {
  position: relative;
  &::before {
    content: "";
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background-color: var(--blue2);
    position: absolute;
    bottom: 104%;
    left: calc(50% - 0.3rem);
  }
}

.fvHeadingItem3 {
  font-size: 1.45em;
  font-weight: bold;
  display: block;
}

.fvLinks {
  margin-top: 4rem;
  display: flex;
  a {
    height: 8.6rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  @keyframes spinner {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }

  .fvLink1 {
    width: 18.4rem;
    border-radius: 0.8rem;
    line-height: 2;
    color: white;
    overflow: hidden;
    z-index: 2;
    &::before {
      content: "";
      background: conic-gradient(
        from -160deg at 50% 50%,
        #4facfe 0deg,
        #00f2fe 120deg,
        #3f6af8 240deg,
        rgba(63, 106, 248, 0) 1turn
      );
      animation: spinner 4s linear infinite;
      transform: translate(-50%, -50%) rotate(0deg);
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 70rem;
      border-radius: 50%;
      z-index: -2;
    }
    &::after {
      content: "";
      position: absolute;
      top: 2px;
      bottom: 2px;
      right: 2px;
      left: 2px;
      background-color: #1c64f2;
      z-index: 1;
    }
    span {
      position: relative;
      z-index: 2;
    }
  }
  .fvLink2 {
    width: 21rem;
    margin-left: 2.4rem;
    color: var(--blue2);
    font-weight: bold;
    span {
      font-size: 1.8rem;
      letter-spacing: 0.2em;
    }
  }
  .fvLinkArrow {
    width: 2.4rem;
    height: 2.4rem;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      content: "";
      width: 1.8rem;
      height: 0.2rem;
      background-color: var(--blue2);
    }
    &::after {
      content: "";
      display: block;
      width: 0.6rem;
      height: 0.6rem;
      margin-left: -0.5rem;
      border-top: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      z-index: 0;
    }
  }
}

.fvLinkIcon {
  position: absolute;
  right: 1.8rem;
}

.flowLogos {
  margin: 0 5vw;
  padding-top: 12rem;
  padding-bottom: 4rem;
  overflow: hidden;
  display: flex;
  mask-image: linear-gradient(
    90deg,
    transparent 0,
    #000 128px,
    #000 calc(100% - 128px),
    transparent
  );
  @media screen and (max-width: 1024px) {
    padding: 40px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 24px 0;
  }
  .logoList {
    // width: 120%;
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    img {
      filter: grayscale(100%);
      opacity: 0.5;
      &:hover {
        opacity: 1;
        filter: none;
      }
    }
    // @media screen and (max-width: 1024px) {
    //   width: 140%;
    // }
    // @media screen and (max-width: 767px) {
    //   width: 180%;
    // }
    // @media screen and (max-width: 450px) {
    //   width: 200%;
    // }
  }
  .logoList:nth-of-type(1) {
    animation: loop 19s linear infinite;
  }
  .logoList:nth-of-type(2) {
    animation: loop2 19s linear infinite;
  }
}

.fvWoman {
  max-width: 39rem;
  width: 30vw;
  position: absolute;
  bottom: -5rem;
  left: 65%;
}

.feature {
  padding: 16rem 0;
}

.featureInner {
  padding: 0 2rem;
  max-width: 124rem;
  margin: 0 auto;
}

.featureList {
  margin-top: 5.6rem;
  margin-bottom: 6.8rem;
  display: flex;
  justify-content: space-between;
}

.featureItem {
  width: 30%;
}
.featureItemImage {
  display: block;
  width: 100%;
  max-width: 36.4rem;
  margin: 0 auto 2rem;
}
.featureItemheading {
  margin-bottom: 4.8rem;
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
}
.featureItemText {
  line-height: 2.8rem;
}

.featureLinks {
  display: flex;
  justify-content: center;
  a {
    width: 23.4rem;
    height: 6.4rem;
    border-radius: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 600;
  }
  a:nth-of-type(1) {
    color: white;
    background-color: var(--blue2);
    margin-right: 4rem;
  }
  a:nth-of-type(2) {
    color: var(--blue2);
    background-color: white;
    border: 1px solid;
  }
}

.magazine {
  padding-top: 8rem;
  padding-bottom: 8rem;
  position: relative;
}

.magazineBg {
  object-fit: cover;
}

.magazineInner {
  max-width: 142rem;
  margin: 0 auto;
  padding: 0 12rem;
  position: relative;
  z-index: 2;
}

.magazineTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8rem;
}

.magazineSectionTitle {
  margin-bottom: 2rem;
  font-size: 2.4rem;
  font-weight: bold;
}

.magazineText {
  line-height: 2.8rem;
}

.magazineConsultLink {
  width: 16rem;
  margin-top: 2.4rem;
  line-height: 5rem;
  font-size: 1.3rem;
  text-align: center;
  border-radius: 2.5rem;
  border: 0.2rem solid;
  background-color: white;
}

.magazineTopLeft {
  width: 42.5rem;
  margin-right: 8rem;
}

.magazineDataList {
  display: flex;
  flex-wrap: wrap;
}

.magazineDataItem {
  width: 33%;
  &:nth-of-type(1) {
    margin-bottom: 6rem;
  }
  &:nth-of-type(3n + 1) {
    text-align: left;
  }
  &:nth-of-type(3n + 2) {
    text-align: center;
  }
  &:nth-of-type(3n + 3) {
    text-align: right;
  }
  dt {
    font-weight: bold;
  }
  dd {
    font-size: 4rem;
    font-weight: bold;
  }
}

.magazineList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.magazineItem {
  width: 48%;
  margin-bottom: 4%;
  border-radius: 2rem;
  overflow: hidden;
  h3 {
    position: absolute;
  }
  img {
    object-fit: cover;
  }
  > a {
    display: block;
    padding-top: 48%;
    position: relative;
  }
}

.magazineItemTexts {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #666, $alpha: 0.5);
  border-radius: 2rem;
  overflow: hidden;
  h3 {
    font-weight: bold;
    text-align: center;
    height: 10.8rem;
    span {
      display: block;
      text-align: center;
      color: white;
    }
    span:nth-of-type(1) {
      font-size: 2rem;
    }
    span:nth-of-type(2) {
      font-size: 4.8rem;
    }
  }
}
.helpfulGuide {
  padding: 16rem 0;
}

.helpfulGuideMessage {
  margin-top: 2.4rem;
  margin-bottom: 6.4rem;
}

.articleLink {
  margin-bottom: 3.6rem;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--green);
  &::after {
    content: ">>";
    margin-left: 3rem;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: -0.2em;
    font-family: monospace;
  }
}

.helpfulGuideBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.helpfulGuideLeft {
  max-width: 92.8rem;
  margin-right: 3rem;
  width: 100%;
}

.helpfulGuideRight {
  width: 39rem;
  flex-shrink: 0;
  margin-top: 3rem;
  background-color: var(--white2);
  padding: 3rem 3rem 5rem;
  border-radius: 1rem;
}

.postList {
  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;
}

.postListItem {
  width: 24%;
  margin-bottom: 1.4%;
  border: 1px solid var(--grey16);
  border-radius: 0.8rem;
  & > a {
    padding: 2rem 1.6rem;
  }
  h3 {
    margin-bottom: 0.8rem;
    margin-top: 1.2rem;
    font-size: 1.2rem;
    font-weight: bold;
  }
  p {
    font-size: 1.3rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.popularPostsTitle {
  margin-bottom: 1.8rem;
  padding-bottom: 0.5rem;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.popularPost {
  &:not(:last-of-type) {
    margin-bottom: 1.8rem;
  }

  &:nth-of-type(1) .popularPostLink::before {
    content: "1";
    background: linear-gradient(to bottom, #d0a900, #fff9e6);
  }
  &:nth-of-type(2) .popularPostLink::before {
    content: "2";
    background: linear-gradient(to bottom, #bdc3c9, #eeeeee);
  }

  &:nth-of-type(3) .popularPostLink::before {
    content: "3";
  }

  &:nth-of-type(4) .popularPostLink::before {
    content: "4";
  }

  &:nth-of-type(5) .popularPostLink::before {
    content: "5";
  }
}

.popularPostLink {
  display: flex;
  &::before {
    content: "1";
    flex-shrink: 0;
    margin-right: 1.4rem;
    width: 2.2rem;
    height: 2.2rem;
    padding-top: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, #815a2b, #eeeeee);
    font-family: var(--sub-font);
    font-size: 1.5rem;
    font-weight: bold;
    transform: translateY(1.8rem);
  }
}
.popularPostImageWrap {
  width: 10rem;
  height: 5.625rem;
  margin-right: 1.8rem;
  position: relative;
  flex-shrink: 0;
  img {
    object-fit: cover;
  }
}

.popularPostTitle {
  font-size: 1.4rem;
  // font-weight: bold;
}
.popularPostDate {
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: var(--grey3);
}

.recommend {
  padding-top: 3rem;
  padding-bottom: 3.2rem;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--mask-black);
    z-index: 2;
  }
}

.recommendBg {
  object-fit: cover;
}

.recommendTopText {
  margin-bottom: 2.8rem;
  span {
    display: block;
    width: fit-content;
    margin: 0 auto;
    white-space: nowrap;
    &:nth-of-type(1) {
      font-size: 2.8rem;
      font-weight: bold;
      color: white;
      border-bottom: 2px solid orange;
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }
    &:nth-of-type(2) {
      font-size: 2.8rem;
      font-weight: 100;
      color: white;
      opacity: 0.92;
    }
  }
}

.recommendHeading {
  margin-bottom: 1rem;
  text-align: center;
  color: white;
  font-size: 3.6rem;
  font-weight: bold;
}

.recommendLink {
  width: 23.4rem;
  height: 6.4rem;
  margin: 0 auto;
  border-radius: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--blue9);
  background-color: white;
}

.concierge {
  padding: 20rem 0 12rem;
}

.conciergeInner {
  display: flex;
  align-items: flex-start;
}

.conciergeMessage {
  margin-top: 15rem;

  font-size: 4.8rem;
  font-weight: bold;

  color: var(--blue13);
  & > span {
    display: block;
    line-height: 1.8;
    > span {
      display: inline-block;
    }
  }
}

.greenLinear {
  background: linear-gradient(
    transparent 68%,
    #d8ed62 68%,

    #d8ed62 92%,
    transparent 92%
  );
}

.voice {
  padding: 8rem 0;
}

.voiceMessage {
  margin-top: 2.4rem;
}

.caseSpecial {
  width: 100%;
  padding: 2.4rem;
  background-color: var(--grey17);
}

.caseSpecialTop {
  margin-bottom: 5.6rem;
  display: flex;
  align-items: flex-start;
}

.caseSpecialImageWrap {
  width: 40%;
  padding-top: 25%;
  margin-right: 2.4rem;
  border-radius: 0.8rem;
  overflow: hidden;
  position: relative;
}

.caseSpecialCompany {
  margin-bottom: 2.4rem;
  font-size: 2rem;
  font-weight: 500;
}

.caseSpecialHeading {
  margin-bottom: 2rem;
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 4.8rem;
}

.caseSpecialBottom {
  display: flex;
  justify-content: space-between;
}

.caseSpecialBottoItem {
  width: 48%;
  display: flex;
}

.caseSpecialBottoTitle {
  width: 9rem;
  height: 9.7rem;
  margin-right: 1.6rem;
  background-color: white;
  border-radius: 0.4rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: 1rem;
  }
}

.caseList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &::after {
    content: "";
    width: calc(395 / 1440 * 100%);
  }
}

.caseListItem {
  margin-top: 4rem;
  width: calc(395 / 1440 * 100%);
  border: 1px solid var(--grey16);
  border-radius: 0.8rem;
  overflow: hidden;
}

.caseListItemTexts {
  padding: 0.8rem;
}

.caseListItemHeading {
  margin-top: 1.2rem;
  margin-bottom: 1.4rem;
  font-weight: bold;
}

.caseListItemText {
  margin-bottom: 2.4rem;
  font-size: 1.4rem;
  line-height: 2;
}

.caseListItemBottom {
  padding-top: 1.2rem;
  padding-bottom: 4.4rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.9;
  color: var(--black5);
  border-top: 1px solid #eee;
}

.caseListItemBottomCompany {
  margin-bottom: 0.6rem;
  font-size: 1.5rem;
}

.caseListItemBottomItem {
  display: flex;
  align-items: flex-start;

  dt {
    flex-shrink: 0;
    margin-right: 1rem;
    &::after {
      content: "：";
    }
  }
}

.caseLink {
  width: 23.4rem;
  height: 6.4rem;
  margin: 4rem auto 0;
  border-radius: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--blue2);
  border: 1px solid;
}

.usageFlow {
  padding: 8rem 0;
}

.flowList {
  margin-top: 9rem;
}
.flowItem {
  display: flex;
  align-items: flex-start;
  padding: 2.4rem 4%;
  border-radius: 0.5rem;
  border: 3px solid var(--grey16);
  &:not(:last-of-type) {
    margin-bottom: 3.2rem;
  }
}
.flowImage {
  flex-shrink: 0;
  margin-right: 7%;
}
.flowTexts {
  flex-grow: 1;
}
.step {
  margin-bottom: 2rem;
  font-size: 2.6rem;
  font-weight: bold;
  font-family: var(--sub-font);
  color: var(--blue2);
  border-top: 2px solid;
  border-bottom: 2px solid;
}

.stepHeading {
  margin-bottom: 1.6rem;
  font-size: 2rem;
  font-weight: bold;
}

.stepText {
  font-weight: bold;
}

.faq {
  padding: 8rem 0;
}

.faqContents {
  max-width: 120rem;
  margin: 0 auto;
}

.faqMessage {
  margin-top: 4rem;
  font-size: 2.4rem;
  font-weight: bold;
}

.faqDetails {
  font-size: 2rem;
  font-weight: bold;
}

.faqQuestion {
  width: 100%;
  padding: 4rem;
  & > div {
    display: flex;
    justify-content: space-between;
  }
}

.faqAnswer {
  padding: 4rem;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  color: var(--red2);
}

.faqIcon {
  margin-right: 2rem;
  flex-shrink: 0;
}

.faqText {
  display: flex;
  align-items: flex-start;
}

.faqOpenIcon,
.faqCloseIcon {
  flex-shrink: 0;
  position: relative;
  width: 3rem;
  height: 3rem;
  margin-left: 2rem;
  &::after {
    content: "";
    width: 0.4rem;
    height: 2rem;
    background-color: black;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &::before {
    content: "";
    width: 2rem;
    height: 0.4rem;
    background-color: black;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.faqDetails[open] {
  .faqOpenIcon {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0.2rem;
    margin-right: 0.8rem;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(135deg);
  }
}
.faqCloseIcon::after {
  display: none;
}
.faqCloseIcon::before {
  width: 1.6rem;
}

.download {
  padding: 8rem 4rem;
}

.downloadList {
  max-width: 102rem;
  margin: 0 auto;
  padding: 4rem 3rem 8rem;
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
}

.downloadItem {
  width: 48%;
  border-radius: 0.4rem;
  overflow: hidden;
}

.downloadSmallText {
  margin: 1.5rem 0;
  font-size: 1.2rem;
  color: #767676;
}

.downloadLargeText {
  margin-bottom: 2.6rem;
  font-weight: bold;
  font-size: 2ren;
}

.downloadLink {
  width: 28rem;
  line-height: 4.8rem;
  text-align: center;
  font-size: 1.8rem;
  color: white;
  background-color: var(--blue2);
  border-color: #191f4d;
  border: 1px solid;
  border-radius: 0.4rem;
}
@media screen and (max-width: 1420px) {
  .fvMessage {
    font-size: 1.7vw;
  }
  .fvHeading {
    font-size: 2.8vw;
  }
  .fvMatchingImage {
    max-width: calc(100% - 2rem);
  }
  .flowLogos {
    padding-top: 8rem;
  }
  .feature {
    padding-top: 4rem;
    padding-bottom: 8rem;
  }
  .featureItem {
    width: 31.5%;
  }
  .featureItemheading {
    font-size: 2.3rem;
  }

  .helpfulGuide {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .postListItem {
    width: 32%;
    &:nth-of-type(7) {
      display: none;
    }
    &:nth-of-type(8) {
      display: none;
    }
  }
  .helpfulGuideLeft {
    margin-right: 5rem;
  }
  .helpfulGuideRight {
    width: 36rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 3rem;
  }

  .concierge {
    padding-top: 6rem;
    padding-bottom: 8rem;
  }

  .conciergeInner {
    flex-direction: column;
    align-items: center;
  }

  .conciergeLeft {
    text-align: center;
  }

  .conciergeMessage {
    margin-top: 8rem;
    margin-bottom: 3rem;
  }
}
@media screen and (max-width: 1100px) {
  .fvLinks .fvLink2 span {
    font-size: 1.6rem;
  }
  .featureItemheading {
    font-size: 2.1rem;
  }
  .magazineInner {
    padding: 0 8rem;
  }
  .magazineTopLeft {
    margin-right: 6rem;
  }
}
@media screen and (max-width: 1000px) {
  .firstView {
    padding: 0;
    margin-bottom: min(64vw, 480px);
  }
  .fvInner {
    padding: 4rem;
    max-width: 73rem;
    margin: 0 auto;
    text-align: center;
    flex-direction: column;
    position: relative;
  }

  .fvTexts {
    width: 100%;
  }

  .fvMessage {
    font-size: 2.4rem;
  }

  .fvHeading {
    font-size: 4rem;
  }

  .fvLinks {
    justify-content: center;
  }

  .flowImage {
    width: 30%;
  }
  .featureList {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .featureItem {
    width: 100%;
    max-width: 40rem;
    &:not(:last-of-type) {
      margin-bottom: 2rem;
    }
  }
  .magazineInner {
    max-width: 56rem;
    padding: 0 1.5rem;
  }

  .magazineTop {
    flex-direction: column;
  }

  .magazineTopLeft {
    margin-bottom: 8rem;
  }

  .magazineList {
    flex-direction: column;
  }

  .magazineItem {
    width: 100%;
    max-width: 49rem;
    margin: 0 auto 4.8rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .helpfulGuideBox {
    flex-direction: column;
    align-items: center;
  }
  .helpfulGuideLeft {
    margin-right: 0;
  }
  .conciergeMessage {
    font-size: 4.2rem;
  }

  .caseListItem {
    width: 32%;
  }
}
@media screen and (max-width: 800px) {
}

@media screen and (max-width: 600px) {
  .sectionInner {
    padding: 0 1.5rem;
  }
  .firstView {
    margin-bottom: 80vw;
  }
  .fvImage {
    display: none;
  }
  .fvInner {
    padding: 0;
  }
  .fvTexts {
    display: flex;
    flex-direction: column;
    padding: 9rem 1.5rem 4rem;
  }
  .fvHeading {
    margin-bottom: 1.6rem;
    order: -1;
    text-align: center;
  }
  .fvHeadingItem1,
  .fvHeadingItem2 {
    font-size: 2.8rem;
    color: #000;
    font-weight: bold;
  }
  .accent::before {
    display: none;
  }
  .fvHeadingItem3 {
    margin-top: 0;
    font-size: 4.8rem;
    background: linear-gradient(90deg, #4158d0, #3f83f8 50%, #000);

    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .fvMessage {
    margin-bottom: 3rem;
    font-size: 1.4rem;
    line-height: 2;
    text-align: center;
    color: var(--grey18);
  }
  .fvMatchingImage {
    display: none;
  }

  .fvLinks {
    width: 34.5rem;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between;
    a:nth-of-type(1) {
      width: 16.7rem;
      height: 5rem;
      span:nth-of-type(1) {
        font-size: 1.2rem;
      }
      span:nth-of-type(2) {
        font-size: 1.4rem;
      }
    }
    a:nth-of-type(2) {
      width: 16.7rem;
      height: 5rem;
      padding-right: 2.2rem;
      margin-top: 0;
      span:nth-of-type(1) {
        font-size: 1.3rem;
      }
    }
  }
  .fvLinkIcon {
    width: 2.5rem;
    right: 0.4rem;
  }

  .flowLogos {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;

    mask-image: linear-gradient(
      90deg,
      transparent 0,
      #000 48px,
      #000 calc(100% - 48px),
      transparent
    );
    .logoList li {
      width: 9rem;
      height: 4rem;
      position: relative;
    }
  }

  .feature {
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: var(--blue14);
  }

  .featureList {
    flex-direction: column;
    margin-bottom: 0;
  }

  .featureItem {
    width: 100%;
    margin-bottom: 6rem;
  }

  .featureItemheading {
    margin-bottom: 1.6rem;
  }

  .featureLinks {
    flex-direction: column;
    align-items: center;
    a:nth-of-type(1) {
      margin-bottom: 2.4rem;
      margin-right: 0;
    }
  }

  .magazine {
    padding-top: 4rem;
    padding-bottom: 6rem;
  }

  .magazineTop {
    margin-bottom: 6rem;
  }

  .magazineTopLeft {
    width: 100%;
    margin-bottom: 4rem;
  }

  .magazineDataItem {
    &:nth-of-type(1) {
      margin-bottom: 3rem;
    }
    dt {
      font-size: 1.3rem;
    }

    dd {
      font-size: 2.4rem;
    }
  }

  .magazineItem {
    margin-bottom: 2.4rem;
  }

  .magazineItemTexts h3 {
    height: 5.6rem;
    span:nth-of-type(1) {
      font-size: 1.4rem;
    }
    span:nth-of-type(2) {
      font-size: 2.4rem;
    }
  }

  .helpfulGuide {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .helpfulGuideMessage {
    margin-top: 2rem;
    margin-bottom: 2.4rem;
    font-size: 1.4rem;
  }

  .helpfulGuideBox {
    flex-direction: column;
  }

  .helpfulGuideLeft {
    width: 100%;
  }

  .postListItem {
    width: 90%;
    margin: 0 auto 2.4rem;
  }

  .helpfulGuideRight {
    width: 100%;
    padding: 3rem 2rem 3rem;
  }

  .recommend {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .recommendHeading {
    font-size: 3.2rem;
    line-height: 1.8;
  }

  .concierge {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .conciergeMessage {
    margin-top: 3rem;
    font-size: 2rem;
  }

  .voice {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .voiceMessage {
    margin-top: 1rem;
    margin-bottom: 3rem;
    font-size: 1.4rem;
  }

  .caseSpecial {
    width: calc(100% + 3rem);
    margin: 0 -1.5rem;
    padding: 0;
  }

  .caseSpecialTop {
    margin-bottom: 0;
    padding: 2.4rem 1.5rem;
    flex-direction: column;
  }

  .caseSpecialImageWrap {
    width: 100%;
    margin-right: 0;
  }

  .caseSpecialCompany {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    font-weight: bold;
  }

  .caseSpecialHeading {
    font-size: 1.6rem;
    line-height: 3rem;
  }

  .caseSpecialBottom {
    padding-top: 5.4rem;
    flex-direction: column;
    align-items: center;
    background-color: white;
  }
  .caseSpecialBottoItem {
    width: 80%;
    flex-direction: column;
    align-items: center;
    &:not(:last-of-type) {
      margin-bottom: 4.8rem;
    }
  }
  .caseSpecialBottoTitle {
    margin-right: 0;
    margin-bottom: 3.4rem;
    background-color: var(--grey17);
  }
  .caseListItem {
    width: 90%;
    margin: 4rem auto 0;
  }

  .usageFlow {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .flowList {
    margin-top: 2.4rem;
  }
  .flowItem {
    padding: 2.4rem 1.5rem 2.4rem 0.6rem;
    border: none;
    border-top-right-radius: 4.8rem;
    background-color: var(--grey19);
    &:not(:last-of-type) {
      margin-bottom: 2.4rem;
    }
  }

  .flowImage {
    display: none;
  }

  .step {
    margin-bottom: 1.6rem;
    padding-bottom: 0.3rem;
    font-size: 1.4rem;
    border-top: none;
    border-bottom: 1px dotted;
  }

  .stepHeading {
    margin-bottom: 2rem;
    font-size: 1.6rem;
  }

  .stepText {
    font-size: 1.3rem;
    line-height: 2.4rem;
    font-weight: 500;
  }

  .faq {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .faqMessage {
    margin-top: 2rem;
    font-size: 1.8rem;
  }
  .faqDetails {
    font-size: 1.3rem;
    line-height: 2.8rem;
  }
  .faqQuestion {
    padding: 1rem;
    & > div {
      align-items: center;
    }
  }
  .faqText {
    width: calc(100% - 4rem);
    align-items: center;
  }
  .faqIcon {
    margin-right: 0.8rem;
  }
  .faqOpenIcon {
    width: 2rem;
    height: 2rem;
    &::before {
      width: 1.5rem;
      height: 0.2rem;
    }
    &::after {
      width: 0.2rem;
      height: 1.5rem;
    }
  }
  .faqDetails[open] {
    .faqOpenIcon {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      transform: translateY(-0.4rem) rotate(135deg);
    }
  }
  .faqAnswer {
    padding: 2rem 1rem;
  }
  .faqCloseIcon {
    height: 2rem;
    width: 2rem;
    margin-right: 0.3rem;
    &::before {
      width: 1rem;
      height: 0.2rem;
    }
  }
}

// AzureSection_CSS
.azureSection {
  max-width: 1300px;
  width: 90%;
  margin: 0 auto;
  padding-top: 8rem;
  padding-bottom: 8rem;
  z-index: 2;
}

.apperSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 32px;
}

.titleCard {
  display: inline-block;
  vertical-align: top;
  width: 30%;
}

.cardContents {
  display: flex;
  justify-content: space-between;
  min-width: 50%;
  padding-left: 2rem;
}

.apperContents {
  max-width: 70%;
}

.gradientBorderButton {
  margin-top: 8rem;

  font-weight: 600;
  font-size: 14px;
  position: relative;
  display: inline-block;
  padding: 12px 28px;
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
  word-wrap: break-word;
  word-break: keep-all;
}

.gradientBorderButton::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  border: 3px solid transparent;
  background-image: linear-gradient(
    135deg,
    #be72ff 0%,
    #5f8aff 25%,
    #00a3ff 50%,
    #1cbad0 75%,
    #37d1a1 100%
  );
  background-origin: border-box;
  background-clip: border-box;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-clip: padding-box, border-box;
  -webkit-mask-composite: destination-out;
  mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  mask-clip: padding-box, border-box;
  mask-composite: exclude;
}

.azureTitle {
  font-weight: bold;
  font-size: 32px;
  margin-top: 64px;
  margin-bottom: 64px;
}

.azureDescription {
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 32px;
}

.card {
  border-radius: 8px;
  width: 47%;
  margin: 16px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

@media (max-width: 1000px) {
  .apperSection {
    flex-direction: column;
    align-items: center;
  }
  .cardContents {
    padding: 0;
  }
  .gradientBorderButton {
    margin-top: 0;
  }
  .azureTitle {
    margin-bottom: 24px;
  }
  .titleCard {
    min-width: 50%;
  }
  .apperContents {
    min-width: 100%;
  }
  .apperContents {
    flex-direction: row;
    justify-content: space-around;
  }
}

@media (max-width: 800px) {
  // .download {
  //   padding: 8rem 4rem;
  // }

  .downloadList {
    flex-direction: column;
  }

  .downloadItem {
    width: 100%;
    margin-bottom: 4rem;
  }

  .downloadSmallText {
    font-size: 1rem;
  }

  // .downloadLargeText {
  //   margin-bottom: 2.6rem;
  //   font-weight: bold;
  //   font-size: 2ren;
  // }

  // .downloadLink {
  //   width: 28rem;
  //   line-height: 4.8rem;
  //   text-align: center;
  //   font-size: 1.8rem;
  //   color: white;
  //   background-color: var(--blue2);
  //   border-color: #191f4d;
  //   border: 1px solid;
  //   border-radius: 0.4rem;
  // }
}
@media (max-width: 600px) {
  .azureSection {
    width: 93%;
  }
  .cardContents {
    flex-direction: column;
    align-items: center;
  }
  .titleCard {
    min-width: 100%;
  }
  .card {
    width: 100%;
    margin: 10px 0;
  }
}

.firstBottomSection {
  margin-bottom: 2rem;
  img {
    max-width: 192px;
    max-height: 108px;
  }
}

.secondBottomSection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  img {
    max-width: 192px;
    max-height: 108px;
  }
}

@media screen and (max-width: 1000px) {
  .secondBottomSection {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 600px) {
  .firstBottomSection {
    img {
      max-width: 122px;
      max-height: 69px;
    }
  }
  .secondBottomSection {
    img {
      max-width: 122px;
      max-height: 69px;
    }
  }
}

@media (max-width: 600px) {
  .download {
    padding: 3rem 1.5rem;
  }

  .downloadList {
    padding: 1.5rem;
  }

  .downloadItem {
    width: 100%;
    margin-bottom: 5rem;
  }

  // .downloadSmallText {
  //   margin: 1rem 0;
  //   font-size: 1.2rem;
  //   color: #bbb;
  // }

  // .downloadLargeText {
  //   margin-bottom: 2.6rem;
  //   font-weight: bold;
  //   font-size: 2ren;
  // }

  .downloadLink {
    font-size: 1.6rem;
  }
}

.partnerSection {
  width: 100%;
  max-width: 150rem;
  margin: 0 auto;
  margin-bottom: 8rem;
  padding: 4rem 2.4rem;
  background-color: #fff;
}

.partnerInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.partnerContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;  // 全体の間隔を調整
}

.partnerTitle {
  text-align: center;
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 3.2rem;
  color: var(--gray-600);
  line-height: 1.5;

  .partnerPowered {
    display: block;
    font-size: 1.8rem;
    font-weight: normal;
    color: var(--gray-500);
    margin-top: 0.8rem;
  }
}

.partnerLogos {
  display: flex;
  align-items: center;
  gap: 4rem;
  margin: 0;  // マージンを削除
}

.partnerLogo {
  max-width: 280px;
  max-height: 157px;
  object-fit: contain;
}

// 既存のcaseLinkスタイルを使用しつつ、マージンを調整
.caseLink {
  width: 23.4rem;
  height: 6.4rem;
  margin: 2rem auto 0;  // 上のマージンを小さく
  border-radius: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--blue2);
  border: 1px solid;
}

@media screen and (max-width: 1000px) {
  .partnerSection {
    padding: 3rem;
    margin-bottom: 6rem;
  }

  .partnerTitle {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 600px) {
  .partnerSection {
    padding: 2rem;
    margin-bottom: 4rem;
  }

  .partnerTitle {
    font-size: 2.4rem;

    .partnerPowered {
      font-size: 1.4rem;
    }
  }

  .partnerContent {
    gap: 1.6rem;  // モバイルでさらに間隔を詰める
  }

  .partnerLogos {
    gap: 2rem;
  }

  .partnerLogo {
    max-width: 160px;
    max-height: 90px;
  }

  .caseLink {
    margin-top: 1.6rem;  // モバイルでさらに詰める
  }
}

