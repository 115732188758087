.tableOfContents {
  width: 30rem;
  padding: 2rem;
  margin-top: -1rem;
  border: 1px solid #eee;
  background-color: white;
  font-size: 1.4rem;
  overflow: auto;
  max-height: calc(100vh - 42rem);
  a {
    color: #65717b;
    &:hover {
      color: black;
      opacity: 1;
    }
  }
}

.tableOfContentsInner {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0.5rem;
    top: 0.8rem;
    bottom: 0.8rem;
    width: 0.2rem;
    background: #e0efff;
  }
  p:last-of-type {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0.5rem;
      top: 0.8rem;
      bottom: 0.8rem;
      width: 0.2rem;
      background: white;
    }
  }
}

.topListItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.8rem;
  font-weight: bold;
  &::before {
    flex-shrink: 0;
    content: "";
    top: 4px;
    left: 0;
    width: 12px;
    height: 12px;
    background: #b0d3f9;
    border: 2px solid #fff;
    border-radius: 50%;
    margin-right: 1rem;
    transform: translateY(0.5rem);
    z-index: 2;
  }
  &.isActive::before {
    border-color: #e0efff;
    background-color: #3ea8ff;
  }
  &.isActive a {
    color: black;
  }
}

.secondListItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.8rem;
  &::before {
    flex-shrink: 0;
    content: "";
    top: 4px;
    left: 0;
    width: 8px;
    height: 8px;
    background: #b0d3f9;
    border: 2px solid #fff;
    border-radius: 50%;
    margin-right: 1.2rem;
    margin-left: 0.2rem;
    transform: translateY(0.7rem);
    z-index: 2;
  }
  &.isActive::before {
    border-color: #3ea8ff;
    background-color: #3ea8ff;
  }
  &.isActive a {
    color: black;
  }
}

@media screen and (max-width: 1000px) {
  .tableOfContents {
    display: none;
  }
}
