.snsLinksVertical {
  width: 3rem;
  margin-right: 3.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 8rem;
  flex-shrink: 0;
  z-index: 10;
  & > a {
    width: 2.8rem;
    margin-bottom: 2.8rem;
    display: flex;
    justify-content: center;
    position: relative;
    img {
      filter: grayscale(100%);
      opacity: 0.5;
      transition: filter 0.3s;
    }
    &:hover {
      opacity: 1;
      img {
        opacity: 1;
        filter: none;
      }
      &::after {
        opacity: 1;
        visibility: visible;
      }
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
    &::after {
      position: absolute;
      top: 120%;
      padding: 0.3rem 1rem;
      z-index: 20;
      content: "";
      font-size: 1.2rem;
      background-color: black;
      color: white;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s;
      white-space: nowrap;
    }
    &::before {
      position: absolute;
      top: calc(120% - 0.5rem);
      content: "";
      z-index: 20;
      width: 0;
      height: 0;
      border-style: solid;
      border-right: 0.5rem solid transparent;
      border-left: 0.5rem solid transparent;
      border-bottom: 0.5rem solid black;
      border-top: 0;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s;
    }
  }
  .x::after {
    content: "X(twiiter)にポスト";
  }
  .fb::after {
    content: "Facebookに投稿";
  }
  .hatena::after {
    content: "はてなブックマークに登録";
  }
  .copy::after {
    content: "URLをコピー";
  }
}

.shareText {
  margin-bottom: 2rem;
  font-size: 1.3rem;
  font-weight: bold;
  writing-mode: vertical-lr;
  letter-spacing: 0.2em;
}

@media screen and (max-width: 1420px) {
  .snsLinksVertical > a {
    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .snsLinksVertical {
    display: none;
  }
}
