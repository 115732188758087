.breadCrumbs {
  box-shadow: var(--shadow4);
}
.inner {
  max-width: 146rem;
  padding: 0 2rem;
  margin: 0 auto;
  overflow: auto;
}
.list {
  display: flex;
}

.listItem {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: var(--black5);
  font-size: 1.4rem;
  line-height: 3.4rem;
  white-space: nowrap;
  padding-right: 1.8rem;
  img {
    margin-right: 0.4rem;
  }
  &:not(:last-of-type)::after {
    content: "";
    margin: 0 0 0 1rem;
    width: 0.7rem;
    height: 0.7rem;

    border-top: 1px solid var(--black5);
    border-right: 1px solid var(--black5);
    transform: rotate(45deg) translateY(0.1rem);
  }
}

.listLink {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .inner {
    padding: 0 1.5rem;
  }
  .listItem {
    font-size: 1.3rem;
    padding-right: 1rem;
    &:not(:last-of-type)::after {
      margin-left: 0.3rem;
    }
  }
}
