.sidebar {
  width: 39rem;
  margin-left: 3rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pickUp {
  width: 100%;
  margin: 4rem 0 5rem;
  padding: 3rem 3rem 5rem;
  background-color: var(--white2);
  border-radius: 1rem;
}

.pickUpText {
  margin-bottom: 1.8rem;
  padding-bottom: 0.5rem;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid var(--grey4);
}

.pickUpListItem {
  &:not(:last-of-type) {
    margin-bottom: 1.8rem;
  }

  &:nth-of-type(1) .pickUpImageWrap::after {
    content: "1";
    background: linear-gradient(to bottom, #d0a900, #fff9e6);
  }

  &:nth-of-type(2) .pickUpImageWrap::after {
    content: "2";
    background: linear-gradient(to bottom, #bdc3c9, #eeeeee);
  }

  &:nth-of-type(3) .pickUpImageWrap::after {
    content: "3";
  }

  &:nth-of-type(4) .pickUpImageWrap::after {
    content: "4";
  }

  &:nth-of-type(5) .pickUpImageWrap::after {
    content: "5";
  }
}

.pickUpLink {
  display: flex;
  align-items: flex-start;
}

.pickUpLeft {
  width: 9.6rem;
  flex-shrink: 0;
}

.pickUpImageWrap {
  padding-top: calc(9 / 16 * 100%);

  position: relative;
  img {
    object-fit: cover;
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 2.2rem;
    height: 2.2rem;
    padding-top: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background: linear-gradient(to bottom, #815a2b, #eeeeee);
    font-family: var(--sub-font);
    font-size: 1.5rem;
    font-weight: bold;
  }
}
.newItems .pickUpImageWrap::after {
  display: none;
}

.pickUpCard {
  margin-left: 2.4rem;
}

.pickUpCardTitle {
  margin-bottom: 0.4rem;
  font-weight: 500;
  line-height: 1.25;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pickUpCardDate {
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  & > img {
    flex-shrink: 0;
    margin-right: 0.2rem;
  }
  font-size: 1rem;
  font-weight: bold;
  color: var(--grey3);
}

.banner1 {
  max-width: 30rem;
  margin-bottom: 3.4rem;
}

.banner2 {
  max-width: 30rem;
  margin-bottom: 0.4rem;
}

.ipro {
  width: 100%;
  padding: 1.7rem 6rem 1.7rem 3.3rem;
  background-color: var(--white2);
  border: 1px solid var(--grey8);
  border-radius: 0.8rem;
}
.iproLogo {
  display: block;
  width: fit-content;
  margin: 0 auto 0.8rem;
}

.iproListItem {
  display: flex;
  align-items: center;
  dt {
    width: 9.2rem;
    font-size: 1.4rem;
    color: var(--black4);
    flex-shrink: 0;
  }
  dd {
    color: var(--black5);
    a {
      margin-right: 0.5rem;
      display: inline;
      text-decoration: underline;
      color: var(--blue4);
      word-wrap: break-word;
    }
  }
  &:not(:last-of-type) {
    margin-bottom: 1.2rem;
  }
}

.stickyItem {
  position: sticky;
  top: 7.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .sidebar {
    width: 29rem;
  }
  .pickUpLeft {
    width: 7rem;
  }
  .pickUpCard {
    margin-left: 1rem;
  }
  .ipro {
    padding: 1.7rem 2.4rem;
  }
}

@media screen and (max-width: 1000px) {
  .sidebar {
    width: 100%;
    margin-left: 0;
    padding-top: 4rem;
  }

  .pickUp {
    width: 100%;
  }
  .pickUpLeft {
    width: 10rem;
  }
  .ipro {
    padding: 1.7rem 3.3rem;
  }
}
@media screen and (max-width: 600px) {
  .pickUp {
    padding: 2rem;
  }
  .pickUpLeft {
    width: 10rem;
  }
  .ipro {
    padding: 1.7rem 2rem;
  }
}
