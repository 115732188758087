.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cardTextSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding-top: 16px;
  padding-left: 32px;
  padding-right: 32px;
}

.cardHeader {
  background-color: #ffffff;
}

.cardIcon {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

.cardTitle {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.cardDescription {
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 3rem;
}

.linkText {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.linkText p {
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
}

.cardImageSection {
  background-color: #f2f2f2;
}

.cardImage {
  width: 100%;
  display: block;
}
