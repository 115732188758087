.slider {
  margin: 8rem auto 0;
  max-width: 126rem;
  padding: 0 7rem;
  position: relative;
  // overflow: visible;
}

.sliderItem {
  width: calc(370 / 1200 * 100%);
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.7rem;
  background-color: white;
  border: 1px solid var(--grey8);
  border-radius: 0.8rem;
  color: black;
}

.documentListLink {
  width: 100%;
  padding: 1rem 1.6rem 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.documentText {
  margin-top: 2.9rem;
  margin-bottom: 4rem;
  line-height: 2.8rem;
}

.tagList {
  display: flex;
  flex-wrap: wrap;
}
.tagItem {
  padding: 0 2rem;
  border-radius: 1rem;
  background-color: #f0f7fc;
  font-size: 1.2rem;
  // font-weight: 300;
  color: var(--black5);
  margin-bottom: 1.5rem;
  &:not(:last-of-type) {
    margin-right: 1.5rem;
  }
}

.prevButton,
.nextButton {
  position: absolute;
  width: 6.4rem;
  height: 6.4rem;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: "";
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(-135deg);
    z-index: 0;
  }
}

.prevButton {
  left: 0rem;
  padding-left: 0.2rem;
  &::after {
    transform: rotate(-135deg);
  }
}

.nextButton {
  right: 0rem;
  padding-right: 0.4rem;
  &::after {
    transform: rotate(45deg);
  }
}

@media screen and (max-width: 800px) {
  .recommendListItem {
    padding: 4rem 0.8rem 6rem;
  }
}

@media screen and (max-width: 600px) {
  .slider {
    padding: 0;
    margin-top: 2.4rem;
  }
  .nextButton,
  .prevButton {
    display: none;
  }
  .recommendListItem {
    width: 100%;
    &:not(:last-of-type) {
      margin-bottom: 1.6rem;
    }
  }
}
