.form {
  padding: 3.2rem 2.4rem 5rem;
  border-radius: 0.5rem;
  background-color: var(--blue11);
}

.formText {
  font-weight: 500;
}
.step {
  margin: 3rem 0;
  display: flex;
  align-items: center;
}

.stepText {
  margin-right: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  color: #000426;
}

.stepList {
  display: flex;
  align-items: center;
}

.stepItem {
  background-color: #eee;
  width: 3.2rem;
  margin-right: 0.6rem;
  text-align: center;
  line-height: 3.2rem;
  border-radius: 50%;
  font-size: 1.4rem;
  &.isActive {
    color: white;
    background-color: #000426;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.button {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  background: #1b224c;
  color: white;
  line-height: 4.2rem;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  font-weight: bold;

  &.isBack {
    background: white;
    border: 1px solid #1b224c;
    color: #1b224c;
  }
}

.privacyPolicyLink {
  color: #00acba;
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .form {
    padding: 2.5rem 1.5rem;
  }
}
