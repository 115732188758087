@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.bottomBanner {
  display: none;
  position: fixed;
  right: 1.5rem;
  bottom: 2rem;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  animation: fade-in 0.3s 1s forwards;
  // a {
  //   &:hover {
  //     opacity: 1;
  //   }
  // }
  button {
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    right: 0rem;
    top: -2.4rem;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    & > span {
      width: 50%;
      height: 0.1rem;
      position: relative;
      & > span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--black6);
        &:nth-of-type(1) {
          transform: rotate(-45deg);
        }
        &:nth-of-type(2) {
          transform: rotate(45deg);
        }
      }
    }
  }
}

.texts {
  background-color: #08d3d3;
  width: 12rem;
  height: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: var(--shadow3);
  color: white;
  p {
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .bottomBanner {
    display: block;
  }
}
