.openButton {
  display: none;
}

.sideList {
  width: 20rem;
  margin-right: 1.5rem;
  flex-shrink: 0;
}
.sideListHead {
  width: 15rem;
  margin-bottom: 0.8rem;
  font-size: 1.2rem;
  font-weight: bold;
  padding-left: 0.5rem;
  line-height: 3rem;
  background-color: var(--grey);
  color: var(--black2);
}
.sideListItem {
  padding-left: 1rem;
  font-size: 1.4rem;
  line-height: 3rem;
  border-left: 2px solid var(--grey2);
  &:last-of-type {
    margin-bottom: 1.2rem;
  }
}
.sideListButton {
  color: var(--black2);
  font-weight: bold;
  white-space: nowrap;
  &.isActive {
    color: var(--blue2);
    opacity: 1;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    margin-top: -1rem;
    margin-bottom: 3rem;
  }
  .openButton {
    display: block;
    max-width: 46.8rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    font-size: 1.6rem;
    position: relative;
    padding: 0 1.6rem;
    background-color: #fff;
    line-height: 5.5rem;
    border: 1px solid var(--grey15);
    border-radius: 0.4rem;
    &::after {
      content: "";
      position: absolute;
      right: 1.4rem;
      top: 2.2rem;
      width: 1rem;
      height: 1rem;
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(135deg);
    }
    &.isShow {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &.isShow::after {
      transform: translateY(0.4rem) rotate(-45deg);
    }
  }

  .sideList {
    max-width: 46.8rem;
    width: 100%;
    margin-bottom: 4rem;
    margin-left: auto;
    margin-right: auto;
    display: none;
    border-left: 1px solid var(--grey15);
    border-bottom: 1px solid var(--grey15);
    border-right: 1px solid var(--grey15);
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    margin-bottom: 0;
  }
  .sideList.isShow {
    display: block;
  }
  .sideListHead {
    width: 100%;
  }
  .sideListGroup {
    display: flex;
    flex-wrap: wrap;
  }
  .sideListItem {
    padding-right: 1.6rem;
    margin-bottom: 1rem;
    border: none;
  }
}
