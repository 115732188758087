.container {
  padding: 4rem 2rem;
}

.titleSection {
  height: 21.2rem;
  position: relative;
  color: white;
  text-align: center;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    font-size: 2rem;
    font-weight: bold;
    position: relative;
    z-index: 2;
  }
  h1 {
    font-size: 4.8rem;
    font-weight: bold;
    position: relative;
    z-index: 2;
  }
}
.bgImgae {
  object-fit: cover;
}

.inner {
  max-width: 146rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.description {
  max-width: 800px;
  margin: 0 auto 20px;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;

  p {
    margin-bottom: 10px;
  }
  
  a {
    color: #0070f3;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1000px) {
  .inner {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .titleSection {
    height: 12rem;

    padding: 0 1.5rem;

    p {
      font-size: 1.6rem;
    }
    h1 {
      font-size: 2.8rem;
    }
  }

  .container {
    padding: 4rem 1.5rem;
  }
}
